
  .ant-table-body{
    scrollbar-width: thin;
  }

  .fixed-toolbar {
    position: fixed;
    top: 0;
    left :0;
    width: 100%;
    background-color: white;
    z-index: 1000;
    padding: 5px 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  .ant-picker-panel-has-range:nth-child(2) ,.ant-picker-panel:nth-child(2){
    display: none;
  }
  .ant-picker-panel:nth-child(1) .ant-picker-header-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panel:nth-child(1) .ant-picker-header-super-next-btn {
    visibility: visible !important;
  }