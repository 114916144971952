.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input, select, textarea{
  font-size: 16px;
}
ul{
  list-style: none;
}
.ant-input{
  font-size: 16px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-content{
  display: flex;
  justify-content: center;
  vertical-align: middle;
  background-color: #eee;
  width: 100%;
  min-height: 100vh;
  padding: 40px 0;
}

.form-button button{
  padding: 0 30px;
}

.body-content{
  width: 100%;
  min-height: 100vh;
  background-color: #eee;
  display: flex;
  vertical-align: middle;
  justify-content: center;
}
.table-content{
  /* width: 100%; */
  min-height: 100vh;
  background-color: #eee;
}
.error-content{
  margin: auto 5px;
}
.no-scroll {
  overflow: hidden;
}
.plan-title{
  font-weight: bold;
  font-size: 110%;
}
.plan-button-title{
  font-weight: bold;
}
.table-content{
  margin: 0 20px;
}

@media (max-width: 526px) {
  .page-form{
    width: 330px;
    padding: 20px 5px;
  }
  .page-title{
    text-align: center;
  }
  .form-button{
    text-align: center;
  }
  .plan-button-title{
    text-align: center;
  }
  .table-content{
    margin: 0 5px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
